<template>
  <div>
    <LoadingBanner
      v-bind:title="'My Documents'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="row mt-3" v-if="!isLoading">
      <div class="col-md-6">
        <div v-if="selectedAssetIds.length > 0">
          <LoadingPanel v-if="isDownloadingAssetIds.length > 0"></LoadingPanel>
          <div v-else>
            <button type="button" class="btn btn-outline-secondary" v-on:click="downloadAll()">Download</button>
            <button type="button" class="btn btn-outline-danger [ ml-2 ]" v-on:click="removeAll()">Remove</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
          <div class="pull-right sm-max-width">
            <a name="add-asset" id="add-asset" class="btn btn-primary sm-max-width" href="/upload" role="button">Add Document</a>
          </div>
      </div>
    </div>

    <div class="row mt-3" v-if="!isLoading">
      <div class="col-md-12">
        <table class="table table-responsive-sm" v-if="assetIds.length > 0">
          <thead>
            <tr>
              <th>
                <input class="table-checkbox" type="checkbox" v-on:click="selectAllAssets($event)">
              </th>
              <th>File Name</th>
              <th class="d-none d-lg-table-cell">File Type</th>
              <th class="d-none d-lg-table-cell">File Size</th>
              <th>Upload Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(id, idx) in assetIds" v-bind:key="id">
              <td scope="row">
                <input :checked="selectedAssetIds.includes(id)" class="table-checkbox" type="checkbox" v-on:click="selectAsset(id, idx)">
              </td>
              <td>{{ assets[id].displayName }}</td>
              <td class="d-none d-lg-table-cell">{{ assets[id].fileExtension }}</td>
              <td class="d-none d-lg-table-cell">{{ assets[id].fileSize }}</td>
              <td>{{ toLocalTime(assets[id].utcUploadDate) }}</td>
              <td>
                <span class="table-icons">
                  <LoadingIcon class="[ p-1 ]" v-if="isDownloadingAssetIds.includes(id)"></LoadingIcon>
                  <span v-else>
                    <i v-on:click="download(id, idx)" class="fa fa-download [ p-1 ]" aria-hidden="true" title="Download"></i>
                    <i v-on:click="remove(id, idx)" class="fa fa-trash [ p-1 ]" aria-hidden="true" title="Delete"></i>
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-info" role="alert" v-if="assetIds.length === 0 && !isLoading">
          <strong>
            You haven't uploaded any documents yet. Please   <a name="add-document" id="add-document" href="/upload" role="button">Add Document</a>
          </strong>
        </div>
      </div>
    </div>
    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import LoadingIcon from "@/components/LoadingIcon"
import LoadingPanel from '@/components/LoadingPanel'
import { default as assetsAPI } from '@/api/AssetsAPI'
import { forceFileDownload, utcToLocalTimeString, setSuccessMessage, setErrorMessage } from '@/utils'
import dayjs from 'dayjs'

export default {
  name: 'AssetMyDocumentsContainer',

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner,
    'LoadingIcon': LoadingIcon,
    'LoadingPanel': LoadingPanel
  },

  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      isDownloadingAssetIds: [],
      selectedAssetIds: [],
      assetIds: [],
      assets: { }, /* { id, asset } */
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    ...mapGetters([
      'currentUser',
    ]),

    load: function() {
      this.clearData();
      this.isLoading = true
      assetsAPI
        .getUploaded(this.currentUser().id)
        .then((assets) => {
          assets.forEach(asset => {
            this.assetIds.push(asset.id)
            this.assets[asset.id] = asset
          });
        })
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isLoading = false)
    },

    remove: function(assetId, idx) {
      if (!confirm('Are you sure you want to delete this file?')) { return }
      this.isDownloadingAssetIds.push(assetId)
      assetsAPI
        .delete(assetId)
        .then(() => {
          this.assetIds.splice(idx, 1)
          this.assets[assetId] = null
          setSuccessMessage(this, 'successMessage')
        })
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => {
          let i = this.isDownloadingAssetIds.findIndex(p => p == assetId)
          this.isDownloadingAssetIds.splice(i, 1)
        })
    },

    download: function(assetId, idx) {
      this.isDownloadingAssetIds.push(assetId)
      assetsAPI
        .download(assetId)
        .then((response) => {
          let asset = this.assets[this.assetIds[idx]]
          forceFileDownload(response, asset.displayName + asset.fileExtension)
          setSuccessMessage(this, 'successMessage')
        })
        .catch((error) => setErrorMessage(this, 'errorMessage'))
        .finally(() => {
          let i = this.isDownloadingAssetIds.findIndex(p => p == assetId)
          this.isDownloadingAssetIds.splice(i, 1)
        })
    },

    toLocalTime(dateTime) {
      return utcToLocalTimeString(dateTime)
    },

    clearData: function() {
      this.isDownloadingAssetIds = []
      this.assetIds = []
      this.assets = {}
      this.selectedAssetIds = []
    },

    selectAsset: function(assetId, idx) {
      let i = this.selectedAssetIds.findIndex(p => p == assetId)
      if (i >= 0) {
        this.selectedAssetIds.splice(i, 1)
      } else {
        this.selectedAssetIds.push(assetId)
      }
    },

    selectAllAssets: function(event) {
      if (event.target.checked) {
        this.selectedAssetIds = [...this.assetIds]
      } else {
        this.selectedAssetIds = []
      }
    },

    downloadAll: function() {
      this.isDownloadingAssetIds = [...this.selectedAssetIds]
      assetsAPI
        .downloadAll(this.selectedAssetIds)
        .then((response) => {
          const dateString = dayjs().format("YYYY-MM-DD hh:mm:ss")
          forceFileDownload(response, 'Portal Download ' + dateString + '.zip')
          setSuccessMessage(this, 'successMessage')
        })
        .catch((error) => setErrorMessage(this, 'errorMessage'))
        .finally(() => {
          this.isDownloadingAssetIds = []
        })
    },

    removeAll: function() {
      if (!confirm('Are you sure you want to delete these files?')) { return }
      this.isDownloadingAssetIds = [...this.selectedAssetIds]
      assetsAPI
        .removeAll(this.selectedAssetIds)
        .then(() => {
          this.selectedAssetIds.forEach(assetId => {
            let idx = this.assetIds.findIndex(p => p == assetId)
            this.assetIds.splice(idx, 1)
            this.assets[assetId] = null
          });
          this.selectedAssetIds = []
          setSuccessMessage(this, 'successMessage')
        })
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isDownloadingAssetIds = [])
    }
  }
}
</script>

<style scoped>

/* Smaller than tablet */
@media only screen and (max-width: 550px) {
  .sm-max-width {
    width: 100%;
  }
}

.table-icons { float: right; width:60px; }
.table-checkbox:hover { cursor: pointer; }
</style>